import * as React from "react";
import ErrorBoundary from "./ErrorBoundary";

/**
 * An HOC to wrap a component in an error boundary. This will forward refs to the wrapped component.
 * @param Component The component to wrap.
 * @param getErrorComponent A function which will be called in the event of an error, and which should return a
 *  component to be displayed in place of the component which threw an error. If getErrorComponent is null, then
 *  null will be rendered in place of the error component.
 */
// function withErrorBoundary<T, P>(
// 	Component: React.StatelessComponent<P>,
// 	getErrorComponent?: (error, props) => React.ReactElement<any>,
// ): React.Component<P>;
// function withErrorBoundary<T, P>(
// 	Component: React.ComponentClass<P>,
// 	getErrorComponent?: (error, props) => React.ReactElement<any>,
// ): React.ForwardRefRenderFunction<T, P>;

type ComponentWithError<P> =
	| React.ComponentClass<P>
	| React.FunctionComponent<P>;

type ErrorComponentGetter<P> = (error: any, props: P) => React.ReactNode;

function withErrorBoundary<T, P>(
	Component: ComponentWithError<React.PropsWithoutRef<P>>,
	getErrorComponent?: ErrorComponentGetter<
		Partial<React.PropsWithoutRef<P>>
	> | null,
) {
	return React.forwardRef<T, P>((props, ref) => {
		let ebGetErrorComponent;
		if (getErrorComponent === null) {
			ebGetErrorComponent = null;
		} else if (getErrorComponent) {
			ebGetErrorComponent = (error) => getErrorComponent(error, props);
		}

		return (
			<ErrorBoundary getErrorComponent={ebGetErrorComponent}>
				<Component {...props} ref={ref} />
			</ErrorBoundary>
		);
	});
}

export { withErrorBoundary };
