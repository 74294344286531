// Not unit tested
import * as React from "react";
import { useContext } from "react";
import scrollToElement from "@mksap/utils/scrollToElement";

export type ScrollToElementType = typeof scrollToElement;
export const ScrollToElementContext = React.createContext(scrollToElement);

interface WrappedComponentProps {
	scrollToElement?: ScrollToElementType;
}

export function withScrollToElement<T>(
	Component:
		| React.FC<T & WrappedComponentProps>
		| React.ComponentClass<T & WrappedComponentProps>,
): React.FC<T> {
	function WrappedComponent(props) {
		return (
			<ScrollToElementContext.Consumer>
				{(contextScrollToElement) => (
					<Component {...props} scrollToElement={contextScrollToElement} />
				)}
			</ScrollToElementContext.Consumer>
		);
	}
	return WrappedComponent;
}

export function useScrollToElement(): ScrollToElementType {
	return useContext(ScrollToElementContext);
}
