// Not unit tested
import { isString } from "lodash-es";
import { offset as getOffset } from "./jQueryShim";
import { getScrollingElement } from "./scrollingElement";

export interface ScrollToElementOptions {
	container?: Element | null;
	disableToolbarOffset?: boolean;
	offset?: number;
	complete?();
}

export const TOOLBAR_OFFSET: number = 0;
export const scrollToElement = (
	elOrSelector: Element | string,
	options: ScrollToElementOptions = {},
) => {
	let el: Element | null;

	if (isString(elOrSelector)) {
		el = document.querySelector(elOrSelector);
	} else {
		el = elOrSelector;
	}

	const { disableToolbarOffset, complete } = options;
	let { container, offset } = options;
	offset = offset || 0;
	if (!disableToolbarOffset && !container) {
		offset = offset - TOOLBAR_OFFSET;
	}

	if (!container && el) {
		container = getScrollingElement(el);
	}

	if (!el || !container) {
		if (complete) {
			setTimeout(complete, 0);
		}
		return;
	}

	// Velocity(el, "scroll", velocityOptions);
	const targetScrollTop =
		getOffset(el)!.top -
		getOffset(container)!.top +
		container.scrollTop +
		offset;

	container.scrollTo({
		top: targetScrollTop,
		left: 0,
		behavior: "smooth",
	});

	if (complete) {
		const startTime = Date.now();
		// Check whether the container element is scrolled to/near the target before calling the complete callback
		const checkContainerScrollTop = () => {
			const runningTimeMs = Date.now() - startTime;
			if (runningTimeMs > 1000) {
				// After 1000ms, just scroll to the element immediately and run the complete callback
				console.log("OK, giving up");
				container!.scrollTo({
					top: targetScrollTop,
					left: 0,
					behavior: "auto",
				});
				requestAnimationFrame(complete);
			} else if (Math.abs(targetScrollTop - container!.scrollTop) < 10) {
				// The container element is close enough. Call the complete callback.
				complete();
			} else {
				// Check again at the next animation frame
				requestAnimationFrame(checkContainerScrollTop);
			}
		};
		requestAnimationFrame(checkContainerScrollTop);
	}
};

export default scrollToElement;
