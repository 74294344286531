/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CORE_QuizAttempt } from '../models/CORE_QuizAttempt';
import type { CORE_QuizForm } from '../models/CORE_QuizForm';
import type { CORE_UnlockToken } from '../models/CORE_UnlockToken';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CoreService {
    /**
     * Get current quiz attempts
     * @returns CORE_QuizAttempt successful
     * @throws ApiError
     */
    public static fetchQuizAttempts(): CancelablePromise<Array<CORE_QuizAttempt>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/core/quiz_attempts.json',
        });
    }
    /**
     * Create quiz attempt
     * @param requestBody
     * @returns CORE_QuizAttempt successful
     * @throws ApiError
     */
    public static createQuizAttempt(
        requestBody?: {
            coreUnlockTokenId: number;
            coreContentAreaId: string;
            coreQuizFormCategory: string;
        },
    ): CancelablePromise<CORE_QuizAttempt> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/core/quiz_attempts.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No matching quiz forms`,
            },
        });
    }
    /**
     * Get all CORE quiz forms
     * @returns CORE_QuizForm successful
     * @throws ApiError
     */
    public static fetchQuizForms(): CancelablePromise<Array<CORE_QuizForm>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/core/quiz_forms.json',
        });
    }
    /**
     * Get current CORE unlock tokens
     * @returns CORE_UnlockToken successful
     * @throws ApiError
     */
    public static fetchUnlockTokens(): CancelablePromise<Array<CORE_UnlockToken>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/core/unlock_tokens.json',
        });
    }
    /**
     * Create unlock token
     * @param requestBody
     * @returns CORE_UnlockToken returned existing unredeemed token
     * @throws ApiError
     */
    public static createUnlockToken(
        requestBody?: {
            coreContentAreaId?: string;
            responseIds?: Array<number>;
        },
    ): CancelablePromise<CORE_UnlockToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/core/unlock_tokens.json',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable entity`,
            },
        });
    }
}
