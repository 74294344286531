import { UIEventHandler } from "react";
import * as React from "react";
import classNames from "classnames";
import autobind from "autobind-decorator";
import { uniqueId } from "lodash-es";
import { Icon } from "./Icon";
import { ScrollToElementContext } from "@mksap/components/context/ScrollToElementContext";
import { inModalRootPortal } from "../utils/inModalRootPortal";
import { withErrorBoundary } from "../errors/withErrorBoundary";
import DialogError from "../errors/DialogError";
import { closest, offset } from "@mksap/utils/jQueryShim";
import { createFocusTrap } from "focus-trap";
import {
	scrollToElement,
	ScrollToElementOptions,
} from "@mksap/utils/scrollToElement";
import { CloseButton } from "./MksapReactBootstrap";

import "./Dialog.scss";

let openModalCount = 0;

export interface DialogProps {
	title?: React.ReactNode;
	header?: React.ReactNode;
	footer?: React.ReactNode;
	contentBelowBody?: React.ReactNode;
	show: boolean;
	className?: string;
	hideOnEscape?: boolean;
	hideOnBackdropClick?: boolean;
	setBodyPaddingToZero?: boolean;
	size?: "sm" | "lg" | "xl";
	// If we find ourselves needing the rest of the fullScreen classes
	// (sm-down, lg-down, xl-down, xxl-down), add them here.
	fullScreen?: true | "md-down" | "lg-down";
	scrollable?: boolean;
	showCloseButton?: boolean;
	jumpToLink?: {
		href: string;
		label: string;
		target?: string;
		onClick?();
	};

	// This is mainly for modals that navigate through content. We want to set .modal-content min-height to 100% in order to
	// prevent the modal from shrinking-then-expanding during content changes.
	setContentToFullHeight?: boolean;

	// By default, the modal will be removed from the dom shortly after it is hidden. This prop prevents that for cases.
	// It may be useful in cases (eg search) where scroll position should be saved when the dialog is reopened.
	preserveComponentWhenHidden?: boolean;
	// Set focus to top of the modal content. This prevents modal action buttons from receiving initial
	// focus and scrolling the content to the bottom.
	focusModalContentTop?: boolean;
	disableFocusTrap?: boolean;
	onHide(): void;
	onScroll?: UIEventHandler<HTMLDivElement>; // TODO: Use this prop
	onResize?(): void;

	/**
	 * Force the modal to display in the flow of the content, rather than as a true modal.
	 * This should really only be used for testing/stories.
	 * */
	forceInline?: boolean;
	children?: React.ReactNode;
}

interface DialogBodyProps extends DialogProps {
	hideElement?: boolean;
}

@autobind
export class DialogBody extends React.Component<DialogBodyProps, {}> {
	static defaultProps = {
		hideOnEscape: true,
		hideOnBackdropClick: true,
		focusModalContentTop: false,
		showCloseButton: true,
	};

	protected modalId: string;
	protected stopAnimatingTimeout: number | null = null;
	protected surfaceRef = React.createRef<HTMLDivElement>();
	protected modalRef = React.createRef<HTMLDivElement>();
	protected modalDialogRef = React.createRef<HTMLDivElement>();
	protected modalBodyRef = React.createRef<HTMLDivElement>();
	protected lastMouseDownWasInDialog: boolean = false;
	protected focusTrap: any;

	constructor(props: DialogBodyProps) {
		super(props);
		this.state = { animating: false };
		this.modalId = uniqueId("modal");
	}

	handleScrollToElement(
		el: Element | string,
		opts: ScrollToElementOptions = {},
	) {
		if (this.modalBodyRef.current) {
			scrollToElement(el, {
				container: this.modalBodyRef.current,
				disableToolbarOffset: true,
				...opts,
			});
		}
	}

	componentDidMount() {
		const { disableFocusTrap } = this.props;
		if (!disableFocusTrap) {
			this.focusTrap = createFocusTrap(this.modalRef.current!);
		}
		if (this.props.show) {
			this.show();
		}
	}

	componentDidUpdate(prevProps: DialogBodyProps) {
		if (this.props.show && !prevProps.show) {
			this.show();
		} else if (!this.props.show && prevProps.show) {
			this.hide();
		}
	}

	componentWillUnmount() {
		this.clearStopAnimatingTimeout();
		this.enableScroll();
		this.untrapFocusOnSurface();
		this.deregisterDocumentKeydownHandler();
	}

	show() {
		this.startAnimating().then(this.afterShowAnimation);
		this.disableScroll();
		// this.trapFocusOnSurface();
		this.registerDocumentKeydownHandler();
		this.registerWindowResizeHandler();
	}

	hide() {
		this.startAnimating().then(this.untrapFocusOnSurface);
		this.enableScroll();
		//this.untrapFocusOnSurface();
		this.deregisterDocumentKeydownHandler();
		this.deregisterWindowResizeHandler();
	}

	startAnimating(): Promise<any> {
		this.clearStopAnimatingTimeout();
		const promise = new Promise(
			function (resolve: () => void) {
				this.setState(
					{
						animating: true,
					},
					() => {
						// Make sure to remove the animating class, even if the transitionend event doesn't trigger correctly.
						this.stopAnimatingTimeout = window.setTimeout(() => {
							this.stopAnimating();
							resolve();
						}, 200);
					},
				);
			}.bind(this),
		);

		return promise;
	}

	stopAnimating() {
		this.clearStopAnimatingTimeout();
		this.setState({ animating: false });
	}

	clearStopAnimatingTimeout() {
		if (this.stopAnimatingTimeout !== null) {
			window.clearTimeout(this.stopAnimatingTimeout);
			this.stopAnimatingTimeout = null;
		}
	}

	handleKeyUp(event: any) {
		if (this.props.hideOnEscape && event.keyCode === 27) {
			this.props.onHide();
		}
	}

	handleModalClick(event: React.MouseEvent<HTMLDivElement>) {
		// Hide the modal when the backdrop is clicked, if requested
		if (!this.props.hideOnBackdropClick) {
			return;
		}

		// Get the nearest modal el to the click
		const targetModalEl = closest(event.target as any, ".modal");

		if (targetModalEl !== this.modalRef.current) {
			// This modal wasn't the closest one to the clicked DOM element. Just return and let that modal handle the event.
			return;
		}

		const closestDialog = closest(event.target as any, ".modal-dialog");
		if (closestDialog === this.modalDialogRef.current) {
			// The click ended inside this dialog, so we definitely shouldn't close
			return;
		}

		// Make sure the click began outside the modal-dialog element
		if (!this.lastMouseDownWasInDialog) {
			this.props.onHide();
		}
	}

	handleModalMouseDown(event: React.SyntheticEvent<HTMLDivElement>) {
		// Keep track of whether each click started within the .modal-dialog, so we can determine whether
		// to close the modal when the click event fires.
		const closestDialog = closest(event.target as any, ".modal-dialog");
		if (closestDialog !== this.modalDialogRef.current) {
			this.lastMouseDownWasInDialog = false;
		} else {
			this.lastMouseDownWasInDialog = true;
		}
	}

	handleTransitionEnd(event: React.TransitionEvent<any>) {
		if (event.target === this.surfaceRef.current) {
			this.stopAnimating();
		}
	}

	afterShowAnimation() {
		this.trapFocusOnSurface();
		this.updateModalPositionCustomProps();
	}

	trapFocusOnSurface() {
		if (this.focusTrap) {
			this.focusTrap.activate();
		}
	}

	untrapFocusOnSurface() {
		if (this.focusTrap) {
			this.focusTrap.deactivate();
		}
	}

	enableScroll() {
		if (this.props.forceInline) {
			return false;
		}
		openModalCount--;
		if (openModalCount <= 0) {
			openModalCount = 0;
			document.querySelector("html")!.classList.remove("modal-open");
		}
	}

	disableScroll() {
		if (this.props.forceInline) {
			return false;
		}
		openModalCount++;
		document.querySelector("html")!.classList.add("modal-open");
	}

	registerDocumentKeydownHandler() {
		document.addEventListener("keyup", this.handleKeyUp);
	}

	deregisterDocumentKeydownHandler() {
		document.removeEventListener("keyup", this.handleKeyUp);
	}

	registerWindowResizeHandler() {
		window.addEventListener("resize", this.onWindowResize);
	}

	deregisterWindowResizeHandler() {
		window.removeEventListener("resize", this.onWindowResize);
	}

	updateModalPositionCustomProps() {
		const modalDialog = this.modalDialogRef.current;
		if (!modalDialog) {
			return;
		}
		const modalLeft = offset(modalDialog)!.left;
		modalDialog.style.setProperty("--modal-left-offset", `${modalLeft}px`);
		// This relies on the fact that our modals are centered horizontally
		modalDialog.style.setProperty("--modal-right-offset", `${modalLeft}px`);
	}

	onWindowResize() {
		this.updateModalPositionCustomProps();
		this.props.onResize && this.props.onResize();
	}

	render() {
		const {
			children,
			className,
			show,
			title,
			header,
			footer,
			contentBelowBody,
			onScroll,
			onHide,
			focusModalContentTop,
			jumpToLink,
			forceInline,
			size,
			fullScreen,
			scrollable,
			showCloseButton,
			setContentToFullHeight,
			setBodyPaddingToZero,
		} = this.props;

		const showHeader =
			typeof title !== "undefined" ||
			typeof header !== "undefined" ||
			showCloseButton;

		const headerWithoutTitle = !title && !header && showCloseButton === true;

		return (
			<>
				<ScrollToElementContext.Provider value={this.handleScrollToElement}>
					<CloseContainingDialogContext.Provider value={onHide}>
						<div
							className={classNames(
								"modal fade",
								className,
								{ show },
								{ "modal-inline": forceInline },
							)}
							role="dialog"
							style={{ display: show ? "block" : "none" }}
							onClick={this.handleModalClick}
							onMouseDown={this.handleModalMouseDown}
							onTouchStart={this.handleModalMouseDown}
							ref={this.modalRef}
							aria-labelledby={`${this.modalId}-title`}
							aria-modal="true"
						>
							<div
								className={classNames("modal-dialog", {
									"modal-sm": size === "sm",
									"modal-lg": size === "lg",
									"modal-xl": size === "xl",
									"modal-fullscreen": fullScreen === true,
									"modal-fullscreen-md-down": fullScreen === "md-down",
									"modal-fullscreen-lg-down": fullScreen === "lg-down",
									"modal-dialog-scrollable": scrollable === true,
								})}
								role="document"
								ref={this.modalDialogRef}
							>
								{focusModalContentTop ? <span tabIndex={0} /> : <noscript />}
								<div
									className={classNames("modal-content", {
										"modal-content-full-height":
											setContentToFullHeight === true,
									})}
								>
									{showHeader && (
										<div
											className={classNames("modal-header", {
												"border-bottom-0": headerWithoutTitle === true,
											})}
										>
											{title ? (
												<h2
													className="modal-title"
													id={`${this.modalId}-title`}
												>
													{title}
												</h2>
											) : (
												header
											)}

											{showCloseButton && <CloseButton onClick={onHide} />}
										</div>
									)}
									{jumpToLink && (
										<div className="jump-to-content">
											<a
												href={jumpToLink.href}
												onClick={jumpToLink.onClick}
												target={jumpToLink.target}
											>
												<span>{jumpToLink.label}</span>{" "}
												<Icon name="arrow-forward" />
											</a>
										</div>
									)}
									<div
										className={classNames("modal-body", {
											"p-0": setBodyPaddingToZero === true,
										})}
										ref={this.modalBodyRef}
										onScroll={onScroll}
									>
										{children}
									</div>
									{footer && (
										<div className="modal-footer d-block">{footer}</div>
									)}
									{contentBelowBody}
								</div>
							</div>
						</div>
					</CloseContainingDialogContext.Provider>
				</ScrollToElementContext.Provider>
				{!forceInline && (
					<div
						className={classNames("modal-backdrop fade", { show })}
						style={{ display: show ? "block" : "none" }}
					/>
				)}
			</>
		);
	}
}

interface DialogTransitionWrapperState {
	renderDialogBody: boolean;
	showDialog: boolean;
}
/**
 * Avoid rendering the actual dialog component until `props.show` is true, but delay passing down `props.show`
 * briefly to make sure the transition works as expected.
 */
class DialogTransitionWrapper extends React.Component<
	DialogProps,
	DialogTransitionWrapperState
> {
	state = this.getInitialState();

	private stopRenderingDialogTimeout: number | null = null;

	getInitialState(): DialogTransitionWrapperState {
		const { show } = this.props;
		return {
			renderDialogBody: show,
			showDialog: show,
		};
	}

	componentDidUpdate(prevProps: DialogProps, _prevState) {
		if (!prevProps.show && this.props.show) {
			// Dialog switched from being hidden to shown

			this.cancelStopRenderingDialogTimeout();

			// Start rendering the dialog body immediately
			if (!this.state.renderDialogBody) {
				this.setState({
					renderDialogBody: true,
				});
			}

			// Start passing the show prop down to the dialog shortly, once it has rendered with show=false
			this.showDialogEventually();
		}

		if (prevProps.show && !this.props.show) {
			// Dialog switched from being shown to hidden
			this.setState({
				showDialog: false,
			});

			// Give time for the transition to end, and then stop rendering the dialog body
			this.stopRenderingDialogEventually();
		}
	}

	/**
	 *  Start passing the show prop down to the dialog shortly, once it has rendered with show=false
	 */
	showDialogEventually() {
		requestAnimationFrame(() => {
			if (this.props.show) {
				this.setState({
					showDialog: true,
				});
			}
		});
	}

	/**
	 * Give time for the transition to end, and then stop rendering the dialog body
	 */
	stopRenderingDialogEventually() {
		this.cancelStopRenderingDialogTimeout();
		this.stopRenderingDialogTimeout = window.setTimeout(() => {
			if (!this.props.show) {
				this.setState({
					renderDialogBody: false,
				});
			}
		}, 300);
	}

	cancelStopRenderingDialogTimeout() {
		if (this.stopRenderingDialogTimeout !== null) {
			clearTimeout(this.stopRenderingDialogTimeout);
			this.stopRenderingDialogTimeout = null;
		}
	}

	componentWillUnmount() {
		this.cancelStopRenderingDialogTimeout();
	}

	render() {
		const { children, show, preserveComponentWhenHidden, ...props } =
			this.props;
		const { renderDialogBody, showDialog } = this.state;

		if (!renderDialogBody && !preserveComponentWhenHidden) {
			return null;
		}

		return (
			<DialogBody
				{...props}
				show={show && showDialog}
				hideElement={!renderDialogBody}
			>
				{children}
			</DialogBody>
		);
	}
}

export function getDialogErrorComponent(
	_error,
	props: { show: boolean; onHide(): void },
) {
	return <DialogError {...props} />;
}
export const Dialog = inModalRootPortal<DialogProps>(
	withErrorBoundary(DialogTransitionWrapper, getDialogErrorComponent),
);

export const CloseContainingDialogContext = React.createContext<() => void>(
	() => {
		/* no-op, in case this is called from a component which is sometimes, but not currently, inside a Dialog */
	},
);
