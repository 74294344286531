import * as React from "react";
import { DialogBody } from "../ui/Dialog";
import DefaultErrorComponent from "./DefaultErrorComponent";
import { Button } from "../ui/Button";

export const DialogError: React.FC<{ show: boolean; onHide(): void }> = ({
	show,
	onHide,
}) => {
	return (
		<DialogBody
			show={show}
			onHide={onHide}
			title="Error"
			showCloseButton
			footer={<Button onClick={onHide}>Close</Button>}
		>
			<DefaultErrorComponent />
		</DialogBody>
	);
};

export default DialogError;
