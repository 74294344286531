import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Returns a ref that indicates whether the component is currently mounted
 */
export function useMountedRef(): MutableRefObject<boolean> {
	const mountedRef = useRef<boolean>(false);

	useEffect(() => {
		mountedRef.current = true;

		return () => {
			mountedRef.current = false;
		};
	});
	return mountedRef;
}
