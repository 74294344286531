import React from "react";
import { Button, ButtonProps } from "./Button";

export const DialogCancelButton: React.FC<ButtonProps> = ({
	variant = "link",
	children = "Cancel",
	...props
}) => {
	return <Button variant={variant} children={children} {...props} />;
};

export const DialogCloseButton: React.FC<ButtonProps> = ({
	variant = "primary",
	children = "Close",
	...props
}) => {
	return <Button variant={variant} children={children} {...props} />;
};

export const DialogDeleteButton: React.FC<ButtonProps> = ({
	variant = "danger",
	children = "Delete",
	...props
}) => {
	return <Button variant={variant} children={children} {...props} />;
};

export const DialogConfirmButton: React.FC<ButtonProps> = ({
	variant = "primary",
	children = "OK",
	...props
}) => {
	return <Button variant={variant} children={children} {...props} />;
};
