// tslint:disable: max-classes-per-file

import { LoggedInUser } from "@mksap/types/loggedInUser";
import { appOptions } from "../appOptions";
import { HttpError, makeAjaxPromise } from "../utils/makeAjaxPromise";
import { ReplaySubject, Subject } from "rxjs";
import { getLoggedInUserNoAuthzApi } from "@mksap/serverApis";

let LoginBackend: ILoginBackend;

export interface LoginResult {
	status: "success" | "not-authorized" | "not-authenticated";
	message: string;
	user?: LoggedInUser;
	authToken?: string;
}

interface ILoginBackend {
	loggedInUserInfoSubject: Subject<LoggedInUser | undefined>;

	logIn(username: string, password: string): Promise<LoggedInUser>;
	logOut(): void;
	saveLoggedInUserInfo(userData: LoggedInUser): void;
	clearSavedLoggedInUserInfo(): void;
	getSavedLoggedInUser(): LoggedInUser | undefined;
	refreshLoggedInUser(): Promise<LoggedInUser | undefined>;
}

abstract class BaseLoginBackend {
	loggedInUserInfoSubject = new ReplaySubject<LoggedInUser | undefined>(1);

	abstract saveLoggedInUserInfo(userData: LoggedInUser): void;
	abstract clearSavedLoggedInUserInfo(): void;
	abstract getSavedLoggedInUser(): LoggedInUser | undefined;

	async doLogIn(
		username: string,
		password: string,
		generateToken: boolean,
		generateTokenWhenUnauthorized: boolean = false,
	): Promise<LoggedInUser> {
		//$.ajax({url: '/api/signin.json', type: 'POST', data: {username: 'bradleyl', password: 'password', generate_token: 1}}).success(function(data){console.log(data)})
		try {
			const userData = await makeAjaxPromise<LoggedInUser>({
				url: appOptions.ajaxPrefix + "/api/signin.json",
				type: "POST",
				data: JSON.stringify({
					username,
					password,
					generate_token: generateToken,
					generate_token_when_unauthorized: generateTokenWhenUnauthorized,
				}),
			});

			this.saveLoggedInUserInfo(userData);

			return userData;
		} catch (err) {
			const response: Response = err.response;
			(window as any).hresponse = response;

			const defaultErrorMessage = "There was an error logging in.";
			if (!response) {
				throw new Error(defaultErrorMessage);
			} else if (response.status === 0) {
				throw new Error(
					"Could not reach ACP MKSAP servers. Please ensure you are connected to the internet and try again.",
				);
			} else {
				if (err.friendlyErrorMessage) {
					throw err;
				} else {
					throw new Error(defaultErrorMessage);
				}
			}
		}
	}

	async refreshLoggedInUser(): Promise<LoggedInUser | undefined> {
		try {
			const userData = await makeAjaxPromise<LoggedInUser>({
				url: getLoggedInUserNoAuthzApi(),
			});
			const authToken =
				userData.authToken ?? this.getSavedLoggedInUser()?.authToken;

			this.saveLoggedInUserInfo({
				...userData,
				authToken,
			});

			return userData;
		} catch (err) {
			if (err instanceof HttpError && err.response.status === 401) {
				this.clearSavedLoggedInUserInfo();
			}
			return undefined;
		}
	}
}

if (appOptions.native) {
	class NativeLoginBackend extends BaseLoginBackend implements ILoginBackend {
		async logIn(username: string, password: string): Promise<LoggedInUser> {
			const generateTokenWhenUnauthorized = !!appOptions.inIOSApp;

			return this.doLogIn(
				username,
				password,
				true,
				generateTokenWhenUnauthorized,
			).catch(async (err) => {
				(window as any).loginError = err;
				console.warn(err);
				if (err?.response) {
					return err.response
						.clone()
						.json()
						.then((bodyJson) => {
							if (bodyJson?.user) {
								this.saveLoggedInUserInfo(bodyJson.user);
								return bodyJson.user;
							}
							throw err;
						})
						.catch((newErr) => {
							console.warn(newErr);
							throw err;
						});
				}

				throw err;
			});
		}

		saveLoggedInUserInfo(userData: LoggedInUser): void {
			try {
				const userJSON = JSON.stringify(userData);
				localStorage.setItem("loggedInUser", userJSON);

				this.loggedInUserInfoSubject.next(userData);
			} catch (e) {
				// Ignore errors and continue
			}
		}

		clearSavedLoggedInUserInfo(): void {
			localStorage.removeItem("loggedInUser");
		}

		logOut(): void {
			// SyncBackend.resetSyncId();
			this.loggedInUserInfoSubject.next(undefined);
			this.clearSavedLoggedInUserInfo();
			(window as any).location = "index.html";
		}

		getSavedLoggedInUser(): LoggedInUser | undefined {
			try {
				const userJSON = localStorage.getItem("loggedInUser");
				if (userJSON) {
					const userData = JSON.parse(userJSON);
					this.loggedInUserInfoSubject.next(userData);
					return userData;
				}
			} catch (e) {
				// Catch errors and allow undefined to be returned
			}
			this.loggedInUserInfoSubject.next(undefined);
			return undefined;
		}
	}

	LoginBackend = new NativeLoginBackend();
} else {
	class WebLoginBackend extends BaseLoginBackend implements ILoginBackend {
		async logIn(username: string, password: string): Promise<LoggedInUser> {
			return this.doLogIn(username, password, false);
		}

		logOut(): void {
			(window as any).location = "/signout";
		}

		saveLoggedInUserInfo(_userData: LoggedInUser): void {
			// Nothing to do on the web
		}

		clearSavedLoggedInUserInfo(): void {
			// Nothing to do on the web
		}

		getSavedLoggedInUser(): never {
			throw new Error("Not implemented in WebLoginBackend");
		}
	}

	LoginBackend = new WebLoginBackend();
}

export default LoginBackend;
