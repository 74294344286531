import { useMountedRef } from "@mksap/components/hooks/useMountedRef";
import { Reducer, useCallback, useMemo, useReducer } from "react";

export interface PromiseState<T = unknown> {
	// status: "not-started" | "pending" | "resolved" | "error";
	pending: boolean;
	succeeded: boolean;
	currentPromise: Promise<T> | null;
	error?: any;
	result?: T;
}

interface PromiseStateTracker<T> extends PromiseState<T> {
	trackPromise(promise: Promise<T>): void;
}

interface PromiseStateReducerAction<T> {
	type: string;
	promise: Promise<T>;
	payload?: any;
}

function promiseStateReducer<T>(
	state: PromiseState<T>,
	action: PromiseStateReducerAction<T>,
): PromiseState<T> {
	if (action.type === "start-promise") {
		return { pending: true, succeeded: false, currentPromise: action.promise };
	} else if (state.currentPromise !== action.promise) {
		// Ignore any actions that aren't for the "current" promise
		return state;
	} else {
		switch (action.type) {
			case "resolve": {
				return {
					pending: false,
					succeeded: true,
					currentPromise: action.promise,
					result: action.payload,
				};
			}
			case "reject": {
				return {
					pending: false,
					succeeded: false,
					currentPromise: action.promise,
					error: action.payload,
				};
			}
		}
	}

	return state;
}

interface UsePromiseStateTrackerOpts {
	// If initiallyPending is true, the PromiseState will be in pending state even before trackPromise is called
	initiallyPending?: boolean;
}
export function usePromiseStateTracker<T = unknown>(
	opts: UsePromiseStateTrackerOpts = {},
): PromiseStateTracker<T> {
	const mountedRef = useMountedRef();
	const [promiseState, dispatch] = useReducer<
		Reducer<PromiseState<T>, PromiseStateReducerAction<T>>
	>(promiseStateReducer, {
		pending: !!opts.initiallyPending,
		succeeded: false,
		currentPromise: null,
	});
	const dispatchIfMounted = useCallback(
		(action: PromiseStateReducerAction<T>) => {
			if (mountedRef.current) {
				dispatch(action);
			}
		},
		[mountedRef, dispatch],
	);

	const trackPromise = useCallback(
		(promise: Promise<T>) => {
			dispatch({ type: "start-promise", promise });

			promise.then(
				(result) => {
					dispatchIfMounted({ type: "resolve", promise, payload: result });
				},
				(error) => {
					dispatchIfMounted({ type: "reject", promise, payload: error });
				},
			);
		},
		[dispatch, dispatchIfMounted],
	);
	return useMemo(() => {
		return {
			...promiseState,
			trackPromise,
		};
	}, [promiseState, trackPromise]);
}

interface usePromiseStateTrackerCallbackOpts {
	allowCallsWhilePending?: boolean;
}
export function usePromiseStateTrackerCallback<T, A extends any[]>(
	memoizedCallback: (...args: A) => Promise<T>,
	options: usePromiseStateTrackerCallbackOpts = {},
): [(...args: A) => void, PromiseStateTracker<T>] {
	const { allowCallsWhilePending } = options;

	const promiseExecutor = usePromiseStateTracker<T>();
	const returnFunction = useCallback(
		(...args: A) => {
			// Don't run the callback while the promise is pending unless allowCallsWhilePending is true
			if (!promiseExecutor.pending || allowCallsWhilePending) {
				promiseExecutor.trackPromise(memoizedCallback(...args));
			}
		},
		[memoizedCallback, promiseExecutor, allowCallsWhilePending],
	);

	return [returnFunction, promiseExecutor];
}
