import * as React from "react";
import noticeError from "@mksap/utils/noticeError";
import DefaultErrorComponent from "./DefaultErrorComponent";

interface ErrorBoundaryProps {
	children?: React.ReactNode;
	getErrorComponent?(error): React.ReactNode;
}
interface ErrorBoundaryState {
	error?: unknown;
}

function defaultGetErrorComponent(_error: unknown): React.ReactNode {
	return <DefaultErrorComponent />;
}

export default class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	static defaultProps = {
		getErrorComponent: defaultGetErrorComponent,
	};

	state: ErrorBoundaryState = {};

	componentDidCatch(error, _info) {
		this.setState({ error });
		noticeError(error);
	}

	render() {
		const { error } = this.state;

		if (!error) {
			return this.props.children as React.ReactElement<{}>;
		} else {
			const { getErrorComponent } = this.props;

			if (getErrorComponent === null) {
				return null;
			}

			try {
				return getErrorComponent!(error);
			} catch {
				// tslint:disable-next-line: no-console
				console.error("Error rendering error component: ", error);
				return defaultGetErrorComponent(error);
			}
		}
	}
}
