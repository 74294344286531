import { indexOf } from "lodash-es";
import ReactDOM from "react-dom";
import React, { useLayoutEffect, useRef, useState } from "react";

interface Showable {
	show: boolean;
}

export function inModalRootPortal<P extends Showable>(
	WrappedComponent: React.ComponentClass<P> | React.FC<P>,
): React.FC<P> {
	const ModalRootPortal: React.FC<P> = (props) => {
		const modalRootRef = useRef<HTMLElement | null | undefined>();
		const [el] = useState(() => document.createElement("div"));
		useLayoutEffect(() => {
			modalRootRef.current = document.getElementById("modal-root");
		});

		const { show } = props;
		useLayoutEffect(() => {
			if (show && indexOf(modalRootRef.current!.children, el) === -1) {
				modalRootRef.current!.appendChild(el);
			}
		}, [show, el]);

		useLayoutEffect(
			() => () => {
				if (indexOf(modalRootRef.current!.children, el) > -1) {
					modalRootRef.current!.removeChild(el);
				}
			},
			[el],
		);

		return ReactDOM.createPortal(<WrappedComponent {...props} />, el);
	};

	return ModalRootPortal;
}
