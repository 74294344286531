import * as React from "react";
import { Alert } from "../ui/MksapReactBootstrap";

export const DefaultErrorComponent: React.FC<{}> = () => {
	return (
		<Alert>
			We're sorry, but something went wrong. We've logged the issue and our
			technical team will take a look. In the meantime, you may be able to
			resolve some issues by refreshing the page.
		</Alert>
	);
};

export default DefaultErrorComponent;
