/* eslint-disable react/jsx-no-target-blank */
import { LoggedInUser } from "@mksap/types/loggedInUser";
import imagePath from "@mksap/utils/imagePath";
import React, { useCallback } from "react";
import {
	Accordion,
	Alert,
	Card,
	Container,
} from "@mksap/components/ui/MksapReactBootstrap";
import { Button } from "@mksap/components/ui/Button";
import { Dialog } from "@mksap/components/ui/Dialog";
import { useShow } from "@mksap/components/hooks/useShow";
import {
	DialogCancelButton,
	DialogConfirmButton,
} from "@mksap/components/ui/DialogButtons";
import { usePromiseStateTrackerCallback } from "@mksap/components/hooks/usePromiseStateTracker";

export interface MksapProduct {
	title: string;
	description: string;
	priceMicros: number;
	currency: string;
	price: string;
	order(): Promise<void>;
}

interface InAppPurchaseSubscribeMessageInnerProps {
	loggedInUser: LoggedInUser;
	product: MksapProduct | null;
	mismatchedAccounts?: boolean;
	hideIapDetails?: boolean;
	logOut(): void;
	restorePurchases(): Promise<void>;
}
export const InAppPurchaseSubscribeMessageInner: React.FC<
	InAppPurchaseSubscribeMessageInnerProps
> = ({
	loggedInUser,
	product,
	mismatchedAccounts,
	restorePurchases: baseRestorePurchases,
	logOut,
}) => {
	const { show: showPurchaseModal, showing, hide } = useShow();
	const [restorePurchases, restorePurchasesPromiseState] =
		usePromiseStateTrackerCallback(baseRestorePurchases);
	const hideIapDetails = mismatchedAccounts;
	return (
		<div className="bg-primary bg-gradient center vh-100 native-app-login-form">
			<div className="web-login-form top-0 start-0 bottom-0 end-0">
				<Container fluid="xl" className="top-50 start-50">
					<div className="row justify-content-center m-md-5">
						<div className="col-md-7 col--form mt-4 mt-md-0">
							<Card className="h-100 d-flex flex-column pt-4 pt-md-0">
								<img
									src={imagePath("acp-mksap-logo-horiz-4c-tagline.svg")}
									className="card-img-top pt-4 px-3"
									alt="ACP MKSAP"
								/>
								<Card.Body>
									<Alert variant="warning">
										<p className="mb-0">You don’t have access to ACP MKSAP.</p>
										<p className="mb-0">
											Logged in as: <b>{loggedInUser.username}</b>{" "}
											<Button variant="link" onClick={logOut}>
												Log out
											</Button>
										</p>

										{mismatchedAccounts && (
											<p>
												Your Apple ID is already associated with an existing ACP
												MKSAP account. Please contact ACP Member and Product
												Support for assistance.
											</p>
										)}
									</Alert>
									<p className="py-3">
										This app requires an active ACP MKSAP subscription from the
										American College of Physicians. Subscriptions purchased via
										the App Store are limited to a Non-member 1-year
										subscription rate and include an Apple Service Fee.
									</p>

									{product && !hideIapDetails && (
										<>
											<ProductAccordion
												product={product}
												showPurchaseModal={showPurchaseModal}
											/>
											{showing && (
												<SubscriptionDetailsModal
													product={product}
													onHide={hide}
												/>
											)}
										</>
									)}

									{!mismatchedAccounts && (
										<div className="text-center">
											<Button
												variant="link"
												onClick={restorePurchases}
												disabled={restorePurchasesPromiseState.pending}
											>
												{restorePurchasesPromiseState.pending
													? "Restoring"
													: "Restore"}{" "}
												Purchases
											</Button>
										</div>
									)}
									<div className="mt-4 small">
										By logging in, you agree to the{" "}
										<a
											target="_blank"
											href="https://www.acponline.org/acp-privacy-policy"
										>
											ACP Privacy Policy
										</a>{" "}
										and the{" "}
										<a
											target="_blank"
											href="https://mksap.acponline.org/license"
										>
											ACP MKSAP Subscription License Agreement
										</a>
										.
									</div>
								</Card.Body>
							</Card>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};

const ProductAccordion: React.FC<{
	product: MksapProduct;
	showPurchaseModal(): void;
}> = ({ product, showPurchaseModal }) => {
	return (
		<Accordion className="my-3">
			<Accordion.Item eventKey="filter1">
				<Accordion.Header>App Store subscription options</Accordion.Header>
				<Accordion.Body>
					<PricingInfo product={product} />
					<div className="text-center mt-3">
						<Button variant="link" onClick={showPurchaseModal}>
							Subscribe
						</Button>
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

const SubscriptionDetailsModal: React.FC<{
	product: MksapProduct;
	onHide(): void;
}> = ({ product, onHide }) => {
	const [order, orderPromiseState] = usePromiseStateTrackerCallback(
		useCallback(() => product.order(), [product]),
	);
	return (
		<Dialog
			show
			title="Confirm"
			showCloseButton
			onHide={onHide}
			scrollable
			footer={
				<div className="d-flex align-items-center justify-content-between">
					<DialogCancelButton
						onClick={onHide}
						disabled={orderPromiseState.pending}
					/>
					<DialogConfirmButton
						onClick={order}
						loading={orderPromiseState.pending}
					>
						{orderPromiseState.pending ? "Processing purchase" : "Continue"}
					</DialogConfirmButton>
				</div>
			}
		>
			<div>
				{product.title}
				<p>Confirm your in-app Non-member 1-year subscription for ACP MKSAP.</p>

				<ul>
					<li>
						By subscribing, you agree to the{" "}
						<a
							target="_blank"
							href="https://www.acponline.org/acp-privacy-policy"
						>
							ACP Privacy Policy
						</a>{" "}
						and the{" "}
						<a target="_blank" href="https://mksap.acponline.org/license">
							ACP MKSAP Subscription License Agreement
						</a>
						.
					</li>
					<li>
						This subscription will be managed by Apple and includes an Apple
						service fee.
					</li>
					<li>
						It will automatically renew yearly through Apple at then-current
						rates with the addition of the Apple service fee.
					</li>
					<li>
						No ACP Member discounts or alternative subscription terms apply when
						purchased via the App Store.
					</li>
					<li>
						This app provides access to all features and content available in
						the web application.
					</li>
					<li>
						Contact the American College of Physicians if you have questions or
						concerns.
					</li>
				</ul>
			</div>
		</Dialog>
	);
};

function getPriceDetails(product: MksapProduct): {
	basePrice: string;
	priceDifference: string;
	price: string;
} | null {
	try {
		if (product.currency !== "USD") {
			return null;
		}

		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
		});

		const oneMillion = 1000000;
		const nonMemberPrice = 825;
		const formattedPrice = formatter.format(product.priceMicros / oneMillion);
		if (product.price !== formattedPrice) {
			console.warn(
				"strange, price formatting did not match. showing fallback",
				product.price,
				formattedPrice,
			);
			return null;
		}

		const priceDifference = product.priceMicros / oneMillion - nonMemberPrice;
		if (priceDifference < 1) {
			console.log(
				"strange, price difference seems too small. showing fallback",
				priceDifference,
			);
			return null;
		} else if (priceDifference > 1000) {
			console.log(
				"strange, price difference seems too large. showing fallback",
				priceDifference,
			);
			return null;
		}

		return {
			basePrice: formatter.format(nonMemberPrice),
			priceDifference: formatter.format(priceDifference),
			price: product.price,
		};
	} catch (e) {
		console.warn(e);
		return null;
	}
}

const PricingInfo: React.FC<{ product: MksapProduct }> = ({ product }) => {
	const priceDetails = getPriceDetails(product);

	if (priceDetails) {
		return (
			<table className="w-100">
				<tbody>
					<tr>
						<td>Non-member 1-year rate:</td>
						<td>{priceDetails.basePrice}</td>
					</tr>
					<tr>
						<td>+ Apple Service Fee:</td>
						<td>{priceDetails.priceDifference}</td>
					</tr>
					<tr style={{ borderBottom: "1px dashed black" }}>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>App Store Non-member 1-year rate: </td>
						<td>{product.price}</td>
					</tr>
				</tbody>
			</table>
		);
	} else {
		return <div>App Store Non-member 1-year rate: {product.price}</div>;
	}
};
