import { appOptions } from "./appOptions";

const { apiPrefix } = appOptions;

export const getContentMetadataApi = (): string => {
	return `${appOptions.getContentPrefix()}/content_metadata.json`;
};

export const getFrontMatterApi = (frontMatterId: string): string => {
	return `${appOptions.getContentPrefix()}/front_matters/${frontMatterId}.json`;
};

export const getFigureApi = (figureId: string): string => {
	return `${appOptions.getContentPrefix()}/figures/${figureId}.json`;
};

export const getTableApi = (tableId: string): string => {
	return `${appOptions.getContentPrefix()}/tables/${tableId}.json`;
};

export const getTopicApi = (chapterId: string): string => {
	return `${appOptions.getContentPrefix()}/topics/${chapterId}.json`;
};

export const getQuestionApi = (questionId: string): string => {
	return `${appOptions.getContentPrefix()}/questions/${questionId}.json`;
};

export const getBookQuestionsApi = (bookId: string): string => {
	return `${appOptions.getContentPrefix()}/questions/by_book/${bookId}.json`;
};

export const starQuestionApi = (questionId: string): string => {
	return `${apiPrefix}/question_stars/${questionId}.json`;
};

export const getLivePeerComparisonApi = (): string => {
	return `${apiPrefix}/responses/live_peer_comparison.json`;
};

export const getResponsesApi = (): string => {
	return `${apiPrefix}/responses.json`;
};

export const getClearResponsesApi = (): string => {
	return `${apiPrefix}/responses/destroy_all.json`;
};

export const getSyncApi = (): string => {
	return `${apiPrefix}/sync.json`;
};

export const getQuizResponsesApi = (quizId: string): string => {
	return `${apiPrefix}/quizzes/${quizId}/responses.json`;
};

export const getQuizApi = (quizId: string): string => {
	return `${apiPrefix}/quizzes/${quizId}.json`;
};

export const createQuizApi = (): string => {
	return `${apiPrefix}/quizzes.json`;
};

export const updateQuizApi = (quizId: number | string): string => {
	return `${apiPrefix}/quizzes/${quizId}.json`;
};

export const deleteQuizApi = (quizId: number | string): string => {
	return `${apiPrefix}/quizzes/${quizId}.json`;
};

export const getHighlightsApi = (): string => {
	return `${apiPrefix}/highlights.json`;
};

export const updateHighlightApi = (highlightId: number): string => {
	return `${apiPrefix}/highlights/${highlightId}.json`;
};

export const deleteHighlightApi = (highlightId: number): string => {
	return `${apiPrefix}/highlights/${highlightId}.json`;
};

export const dismissInvalidatedHighlightsAlertApi = (): string => {
	return `${apiPrefix}/highlights/dismiss_invalidated_alert.json`;
};

export const getHighlightableNodesApi = (): string => {
	return `${apiPrefix}/highlights/highlightable_nodes_for_hl_ids.json`;
};

export const getNotesApi = (): string => {
	return `${apiPrefix}/notes.json`;
};

export const deleteNoteApi = (noteId: number): string => {
	return `${apiPrefix}/notes/${noteId}.json`;
};

export const getSearchApi = (queryStr: string): string => {
	return `${apiPrefix}/search.json?${queryStr}`;
};

export const getCmeApi = (): string => `${apiPrefix}/cme.json`;

export const getMocApi = (): string => `${apiPrefix}/moc.json`;

export const getSubmissionsApi = (): string => `${apiPrefix}/submissions.json`;
export const getSubmissionDetailsApi = (submissionId: string): string =>
	`${apiPrefix}/submissions/${submissionId}.json`;
export const abimInfoApi = (): string =>
	`${apiPrefix}/submissions/abim_info.json`;

export const markFlashcardsApi = (): string => {
	return `${apiPrefix}/flashcard_statuses.json`;
};

export const clearFlashcardsApi = (): string => {
	return `${apiPrefix}/flashcard_statuses/destroy_all.json`;
};

export const getLoggedInUserApi = (): string => {
	return `${apiPrefix}/user.json`;
};

export const getLoggedInUserNoAuthzApi = (): string => {
	return `${apiPrefix}/user/without_authorization.json`;
};

export const loadImIteResultsApi = (): string => {
	return `${apiPrefix}/im_ite_results.json`;
};

export const findImIteResultsApi = (): string => {
	return `${apiPrefix}/im_ite_results/find_results.json`;
};

export const linkImIteResultsApi = (): string => {
	return `${apiPrefix}/im_ite_results/link_result.json`;
};

export const saveRecentActivityApi = (): string => {
	return `${apiPrefix}/recent_activities.json`;
};

export const dismissAlertApi = (): string => {
	return `${apiPrefix}/dismissible_alerts.json`;
};

export const inAppPurchasesApi = (): string => {
	return `${apiPrefix}/in_app_purchases.json`;
};
